import React, { FC, useEffect, useState } from 'react'
import Modal from './Modal'

const MediaModal: FC<MediaModalProps> = ({ visible, src, onClose }) => {
	const height = window.innerHeight - 150
	/*
		const onChanges = function (changesObj) {
			var url = resolve.url()
			var path = 'media'
			if (url && url.substr(0, path.length) === path) {
				path = url.substr(0, url.indexOf('/'))
			}
			mediaPath = path + '.html'
			mediaUrl = url
		}*/
	const ok = function () {
		onClose('TODO: path')
	}

	return <>
		<iframe src={src} width="100%" height={height}></iframe>
		<div className="input-group">
			<span className="input-group-addon"><i className="fa fa-link"></i></span>
			<input className="form-control" type="text" id="modalMediaUrl" ng-model="mediaUrl" />
			<span className="input-group-btn">
				<button className="btn btn-primary" type="submit"><i className="fa fa-check"></i> Сонгох</button>
				<button className="btn btn-warning" onClick={() => onClose()}>Болих</button>
			</span>
		</div>
	</>
}
type MediaModalProps = {
	visible: boolean
	src: string
	onClose: any
}
export default MediaModal