import { createContext, useEffect, useState } from 'react'
import { ItemContextType } from '../types/ItemContextType'
import Element from '../components/Element'
import Item from '../types/Item'

export const ItemContext = createContext<ItemContextType>({
  item: {},
  setItem: () => {
    throw new Error('Function not implemented.')
  }
})

interface ItemProviderProps {
  layout: any
  item: Item
}

export const ItemProvider: React.FC<ItemProviderProps> = ({ layout, item }) => {
  // Вебийн тохиргоог агуулна.
  const [_item, setItem] = useState<Item>()
  // Хуудас солигдох үед мэдээллийг шинээр дүрсэлнэ.
  useEffect(() => {
    setItem(item)
  }, [item])

  const value = { item: _item, setItem }
  // Модал цонх дүрслэх үед _item хоосон утгатай байх тул утга байгаа эсэхийг шалгав.
  return _item && <ItemContext.Provider value={value}>
    <Element properties={layout} />
  </ItemContext.Provider>
}
