
import { FC } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { getValue } from '../../utils/valueUtils'
// list-edit
const Dg3: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const title = getValue(properties, 'title')
	const options = getValue(properties, 'values') as []
	const required = getValue(properties, 'required')
	const readonly = getValue(properties, 'readonly')

	const handleChange = (event: any) => {
		onChange(event.target.value)
	}
	// TODO: checkbox, radio нэмэх
	return <select required={required} disabled={readonly} value={value} onChange={handleChange}>
		<option value="">---{title}---</option>
		{options.map((option: any, i) => <option key={i} value={option}>{option}</option>)}
	</select>
}
export default Dg3