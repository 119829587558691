import { FC } from 'react'
import * as valueUtils from '../../utils/valueUtils'
import Modal from './Modal'

const PermissionModal: FC<PermissionModalProps> = ({ title, item, visible, onClose }) => {
    const layout = { layout: require('../../json/json.json') }
    const _item = { values: [{ field: "desc", value: JSON.stringify(item) }] }
    const handleClose = () => {
        onClose(JSON.parse(valueUtils.getValue(_item, 'desc')))
    }
    return <Modal layout={layout} item={_item}
        title={title} visible={visible}
        onClose={handleClose} />
}
type PermissionModalProps = {
    visible: boolean
    onClose: any
    item: any
    title: string
}

export default PermissionModal