import { FC } from 'react'
import * as valueUtils from '../../utils/valueUtils'
import Modal from './Modal'

const JsonModal: FC<JsonModalProps> = ({ item, visible, onClose }) => {
    const layout = require('../../json/json.json')
    const title = 'JSON'
    const _item = { values: [{ field: "desc", value: JSON.stringify(item) }] }
    const handleClose = () => {
        onClose(JSON.parse(valueUtils.getValue(_item, 'desc')))
    }
    return <Modal layout={layout} item={_item}
        title={title} visible={visible}
        onClose={handleClose} />
}
type JsonModalProps = {
    visible: boolean
    onClose: any
    item: any
}

export default JsonModal