import { useState, useEffect, FC, useContext } from 'react'
import EditElementProps from '../../types/EditElementProps'
import Item from '../../types/Item'
import { SiteContext } from '../../contexts/SiteContext'
import { getValue, getCategories } from '../../utils/valueUtils'
import { ItemContext } from '../../contexts/ItemContext'
// Системийн үндсэн талбаруудыг дүрсэлнэ
const Dg1: FC<EditElementProps> = ({ properties, onChange }) => {
    const { item } = useContext(ItemContext)
	const [value, setValue] = useState(item[properties.id])
	const [options, setOptions] = useState([] as { id: string | number | undefined, name: string }[])
	const required = getValue(properties, 'required')
	const readonly = getValue(properties, 'readonly')
	const { site } = useContext(SiteContext)
	useEffect(() => {
		if (properties.id === 'category') {
			setOptions((Object.values(getCategories(site)) as Item[]).map((item: Item) => {
				var name = getValue(item, 'title')
				return { id: item.id, name: name }
			}).filter((item: any) => item.id > 0))
		} else if (properties.id === 'status') {
			setOptions([
				{ id: 10, name: 'Хаалттай' },
				{ id: 20, name: 'Нийтлээгүй' },
				{ id: 30, name: 'Нийтэлсэн' }
			])
		}
	})
	const handleChange = (event: any) => {
		item[properties.id] = event.target.value
		onChange()
		setValue(event.target.value)
	}
	const title = getValue(properties, 'title')
	return <div className={properties.class}>
		<label>{title}</label>
		{readonly ? <span>{value}</span> :
			properties.id === 'name' ?
				<input type="text" placeholder={title} required={required} value={value ?? ''} onChange={handleChange} /> :
				properties.id in ['id', 'parent', 'order'] ?
					<input type="number" placeholder={title} required={required} value={value ?? ''} onChange={handleChange} /> :
					properties.id in ['created', 'updated'] ?
						<input step="1" type="datetime-local" placeholder={title} required={required} value={value ?? ''} onChange={handleChange} /> :
						properties.id in ['status', 'category'] ?
							<select required={required} onChange={handleChange}>
								{options.map((item: any, i) => value === item.id ?
									<option key={i} value={item.id} selected>
										{item.name}
									</option> :
									<option key={i} value={item.id}>
										{item.name}
									</option>)}
							</select> : null}
	</div>
}
export default Dg1