import { useNavigate } from 'react-router-dom'
import * as MeService from '../services/MeService'

const Logout = () => {
    const navigate = useNavigate()
    MeService.signOut().then(() => {
        navigate('/')
    })
    return <></>
}
export default Logout