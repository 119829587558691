import { FC, useContext } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { SiteContext } from '../../contexts/SiteContext'
import { getCategory } from '../../utils/valueUtils'
import { ItemContext } from '../../contexts/ItemContext'
// category-edit
const Dg7: FC<EditElementProps> = ({ properties, onChange }) => {
    const { item } = useContext(ItemContext)
	const { site } = useContext(SiteContext)
	const getCategoryPath = function () {
		var category = getCategory(site, item.category)
		if (category) {
			var path = [category]
			while ('parent' in category) {
				path.push(category.parent)
				category = category.parent
			}
			return path
		}
		return []
	}
	const getSubCategories = function () {
		var categories = []
		var category = getCategory(site, item.category)
		if (category) {
			for (var i in category.items) {
				if (category.items[i].inherit) {
					categories.push(category.items[i])
				}
			}
		} else {
			categories.push(getCategory(site, -1))
			/* TODO: Shaardlagataii esehiig shalgah
			if (window.category) {
				categories.push(window.category)
			}*/
		}
		return categories
	}
	const selectCat = function (category: any) {
		item.category = category.id
	}
	const removeCat = function (category: any) {
		if ('parent' in category) {
			item.category = category.parent.id
		} else {
			item.category = null
		}
	}
	return <>
		<div className="category-control">
			{getCategoryPath().map(category => <button type="button" className="btn btn-xs btn-success" onClick={() => { removeCat(category) }}>{category.title} <i className="fa fa-remove"></i></button>)}
		</div>
		<div className="category-options">
			{getSubCategories().map(category => <button type="button" className="btn btn-xs btn-default" onClick={() => { selectCat(category) }}><i className="fa fa-plus"></i> {category.title}</button>)}
		</div>
	</>
}
export default Dg7