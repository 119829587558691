import { FC } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { getValue } from '../../utils/valueUtils'
/**
 * memo-edit
 * @param param0 
 * @returns 
 */
const Dg10: FC<EditElementProps> = ({ properties, onChange, value }) => {
	const title = getValue(properties, 'title')
	const className = getValue(properties, 'className')
	const required = getValue(properties, 'required')
	const readonly = getValue(properties, 'readonly')
	return <textarea placeholder={title} required={required} readOnly={readonly}
		className={className} onChange={e => onChange(e.target.value)}>{value}</textarea>
}
export default Dg10