import { useState, useEffect, FC, useContext } from 'react'
import Element from '../Element'
import * as SiteService from '../../services/SiteService'
import * as ItemService from '../../services/ItemService'
import * as UserService from '../../services/UserService'
import WidgetProps from '../../types/WidgetProps'
import { SiteContext } from '../../contexts/SiteContext'
import { collectFields, getCategory, getMultiParents, getValue, slugify } from '../../utils/valueUtils'
import { handleRequestError } from '../../utils/responseUtils'
import { ItemContext } from '../../contexts/ItemContext'
/**
 * Маягт
 */
const Dg5006: FC<WidgetProps> = ({ properties }) => {
    const { item } = useContext(ItemContext)
    const [oItem, setOItem] = useState()
    const { site } = useContext(SiteContext)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setOItem(structuredClone(item))
    })
    // Мэдээллийн хуулбарыг хадгалах хувьсагч
    const optimizeUpdateData = (data: any) => {
        // name бичсэн бол зөвшөөрөгдөөгүй тэмдэгтүүдийг цэвэрлэнэ
        data.name = data.name ? slugify(data.name) : ''

        console.log('update', data, oItem)
        // эх өгөгдлийг хөндөхгүйн тулд хувилж авна
        let changedItem: any = removeUnchangedFields(data, oItem)
        // хоосон талбаруудыг цэвэрлэнэ
        removeEmptyValues(changedItem)
        if (changedItem.category > 0) {
            // олон ангилалд багтдаг бол эх ангиллуудыг нь оруулна
            changedItem.categories = getMultiParents(site, getCategory(site, changedItem.category))
        }
        return changedItem
    }
    const optimizeInsertData = (data: any) => {
        data.name = data.name ? slugify(data.name) : ''
        var item = { ...data }
        removeEmptyValues(item)
        if (item.category > 0) {
            item.categories = getMultiParents(site, getCategory(site, item.category))
        }
        return item
    }
    /**
     * Мэдээллийн түлхүүр утга болон өөрчлөгдсөн хэсгийг серверт илгээнэ
     * @param {*} e 
     */
    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (e.currentTarget !== e.target) {
            // Сонсож байгаа элемент, дуудсан элемент 2 нь ижил биш бол
            // ямар ч үйлдэл хийхгүй.
            // 2 формын submit үйлдэл давхар дуудагдахыг зогсоов.
            console.log('submit skip duplicate')
            return
        }
        // Item нь ангилалтай бол үндсэн мэдээлэл гэж үзээд хадгалах үйлдэл хийнэ.
        if ('category' in item) {
            setIsLoading(true)
            // bind хийсэн утгуудыг олгоно
            collectFields(properties.items).forEach((field: any) => {
                if (field.bind > 0 && !item[field.id] && item[field.id] !== '') {
                    item[field.id] = getValue(item, field.bind)
                }
            })
            // Мэдээллийн төрлөөс хамаарч тохирох сервисийг ажиллуулна
            if (item.category === 0) {
                // Хэрвээ веб сайтын тохиргоо бол
                const clear = (value: any) => {
                    if (value && typeof value === 'object') {
                        if ('parentId' in value) {
                            delete value.parentId
                        }
                        if ('items' in value) {
                            if (value.items.length > 0) {
                                value.items.forEach((child: any) => clear(child))
                            } else {
                                delete value.items
                            }
                        }
                        if ('path' in value) {
                            delete value.path
                        }
                    }
                }
                item.values.forEach((value: any) => clear(value.value))
                delete item.default
                SiteService.edit(optimizeUpdateData(item)).then(res => res.json()).then(() => {
                    window.location.reload()
                }).catch(e => {
                    setIsLoading(false)
                    handleRequestError(e)
                })
            } else if (item.category === -3) {
                // Хэрвээ хэрэглэгч бол
                if (item.id > 0) {
                    UserService.edit(optimizeUpdateData(item)).then(res => res.json()).then((value: any) => {
                        setIsLoading(false)
                        //onChange(item)
                    }).catch(e => {
                        setIsLoading(false)
                        handleRequestError(e)
                    })
                } else {
                    UserService.add(optimizeInsertData(item)).then(res => res.json()).then((value: any) => {
                        setIsLoading(false)
                        item.id = value.id
                        //onChange(item)
                    }).catch(e => {
                        setIsLoading(false)
                        handleRequestError(e)
                    })
                }
            } else if (item.id > 0) {
                // Хэрвээ өмнө нь оруулсан мэдээлэл бол
                ItemService.edit(optimizeUpdateData(item)).then(res => res.json()).then((value: any) => {
                    setIsLoading(false)
                    //onChange(item)
                }).catch(e => {
                    setIsLoading(false)
                    handleRequestError(e)
                })
            } else {
                // Хэрвээ шинээр оруулж буй мэдээлэл бол
                ItemService.add(optimizeInsertData(item)).then(res => res.json()).then((value: any) => {
                    setIsLoading(false)
                    item.id = value.id
                    //onChange(item)
                }).catch(e => {
                    setIsLoading(false)
                    handleRequestError(e)
                })
            }
        } else {
            //onChange(item)
            Object.assign(item, item)
        }
    }

    return <form className={properties.class + (isLoading ? ' loading' : '')} onSubmit={handleSubmit}>
        {properties.items.map((element: any, i: number) =>
            <Element key={i} properties={element} />)}
    </form>
}

const removeEmptyValues = (item: any) => {
    for (var i in item) {
        if (item[i] === null || item[i] === false || item[i] === '') {
            // NULL эсвэл хоосон тэмдэгт утгатай шинж чанар бол устгана
            delete item[i]
        } else if (Array.isArray(item[i])) {
            // Массив бол
            item[i].forEach((prop: any, j: number) => {
                // Элемент бүр дээр цэвэрлэх үйлдлийг дуудна
                if (typeof prop === 'object' && !Array.isArray(prop)) {
                    removeEmptyValues(prop)
                    if (Object.keys(prop).length === 0 ||
                        ('field' in prop && !('id' in prop) && !('value' in prop))) {
                        // Массивын элемент хоосон обьект бол устгана
                        delete item[i][j]
                    }
                }
            })
            // Массивын хоосон элементүүдийг устгана
            item[i] = item[i].filter((prop: any) => { return prop != null })
            if (item[i].length === 0) {
                // Хоосон массив утгатай шинж чанар бол устгана
                delete item[i]
            }
        } else if (typeof item[i] === 'object') {
            // Обьект бол
            removeEmptyValues(item[i])
            if (Object.keys(item[i]).length === 0) {
                delete item[i]
            }
        }
    }
}
/**
 * Өөрчлөгдөөгүй талбаруудыг устгана
 * @param {object} item Өөрчлөгдсөн мэдээлэл
 * @param {object} oItem Анхны мэдээлэл
 * @returns 
 */
const removeUnchangedFields = (item: any, oItem: any) => {
    const result = Object.fromEntries(Object.entries(item).filter(([key, value]) =>
        ['id', 'category', 'values'].includes(key) ||
        (typeof value === 'object' && JSON.stringify(value) !== JSON.stringify(oItem[key])) ||
        (typeof value !== 'object' && value !== oItem[key])
    ))

    if (result.values) {
        result.values = (result.values as any[]).filter((value: any) => {
            // Харгалзах шинэ утгыг олно
            var index = oItem.values.findIndex((oValue: any) =>
                value && oValue.id === value.id)
            // Харгалзах шинэ утга байгаа эсэхийг шалгана
            if (index > -1) {
                // Харгалзах шинэ утга байвал
                if (value.value === oItem.values[index].value) {
                    // Анхны утгатай ижил бол өөрчлөгдөөгүй учир шинээс хасна
                    return false
                } else if (typeof value.value === 'object' && JSON.stringify(value.value) === JSON.stringify(oItem.values[index].value)) {
                    return false
                }
            }
            return true
        })
    }
    // Анхны утгад байхгүй шинээр нэмэгдсэн утгууд хэвээрээ үлдэнэ
    return result
}
export default Dg5006