import { useState, FC, useContext } from 'react'
import EditElementProps from '../../types/EditElementProps'
import { getValue, collectLayout } from '../../utils/valueUtils'
import Modal from '../modal/Modal'
import { SiteContext } from '../../contexts/SiteContext'
import Builder from './Builder'
import ElementProps from '../../types/ElementProps'
import JsonModal from '../modal/JsonModal'
// layout-edit
type BAProps = {
    After: typeof After
}
// Хуудасны бүтэц дүрслэх компонент
const Dg41: FC<EditElementProps> & BAProps = ({ properties, onChange, value }) => {
    const { site } = useContext(SiteContext)
    const getLayout = (fieldId: any) => {
        let value = getValue(properties, fieldId)
        if (value !== null) {
            value = getValue(site.default, value)
            if (value !== null) {
                return collectLayout(value, 'form')
            }
        }
    }
    const itemLayouts = getLayout('item')
    const fieldLayouts = getLayout('field')
    const className = properties.class ? 'tree-edit ' + properties.class : 'tree-edit'
    // Үндсэн зангилааг үүсгэнэ.
    const root = { items: value ? [value] : [] }
    const handleChange = () => {
        // Үндсэн зангилаанд элемент байхгүй бол хоосон утга
        // байвал уг элементийг утга болгон илгээнэ.
        if (root.items.length === 0) {
            onChange(null)
        } else {
            onChange(root.items[0])
        }
    }
    return <Builder className={className} root={root} onChange={handleChange}
        renderNode={(node: any, onRemove: any) => <ElementTmpl node={node} fieldLayouts={fieldLayouts} itemLayouts={itemLayouts} onRemove={onRemove} />} />
}
// Элемент дүрслэх компонент
const ElementTmpl: FC<ElementTmplProps> = ({ node, fieldLayouts, itemLayouts, onRemove }) => {
    const element = node
    const title = getValue(element, 'title')
    const type = getValue(element, 'type')
    const [jsonValue, setJsonValue] = useState(null as any)
    const [modalValue, setModalValue] = useState({} as any)

    const showDetails = function () {
        var type = getValue(element, 'type')
        type = type === null ? 0 : type
        setModalValue({
            item: element,
            layout: getValue(type < 5000 ? fieldLayouts[type] : itemLayouts[type], 'layout'),
            title: type < 5000 ? `Төрөл: ${type}, талбар: ${element.id}` : `Булан: ${type}`,
            onClose: (item: any) => {
                if (item) {
                    let element = JSON.parse(getValue(item, 'desc'))
                }
                setModalValue({})
            }
        })
    }
    const onShowJson = () => {
        setJsonValue(node)
    }
    const handleJsonClose = (item: any) => {
        Object.assign(item, item)
        setJsonValue(null)
    }
    return <div className="tree-node">
        {!element.name && <div className="tree-handle"><span className="fa fa-arrows"></span></div>}
        <a className="title" onClick={showDetails}>
            {element.name || title || type}
        </a>
        <div className="tools">
            <a className="json" onClick={onShowJson}>
                <span className="fa fa-file-code-o" title="JSON"></span>
            </a>
            <a className="remove" onClick={onRemove}>
                <span className="fa fa-times" title="Устгах"></span>
            </a>
        </div>
        <Modal layout={modalValue.layout} item={modalValue.item}
            title={modalValue.title} visible={modalValue.item != null}
            onClose={modalValue.onClose} />
        <JsonModal item={jsonValue} visible={jsonValue != null} onClose={handleJsonClose} />
    </div>
}
type ElementTmplProps = {
    node: any
    fieldLayouts: any[]
    itemLayouts: any[]
    onRemove: any
}
const After: FC<ElementProps> = ({ properties }) => {
    const { site } = useContext(SiteContext)
    const [activeTab, setActiveTab] = useState(0)
    const getLayout = (fieldId: any) => {
        var value = getValue(properties, fieldId)
        if (value !== null) {
            value = getValue(site.default, value)
            if (value !== null) {
                return collectLayout(value, 'form')
            }
        }
    }
    const getItems = (layouts: any) => Object.keys(layouts).map((key: any) => {
        var layout = layouts[key]
        var result: any = {
            values: [
                { field: 'type', value: layout.id },
                { field: 'title', value: getValue(layout, 'title') }
            ]
        }
        if (getValue(layout, 'contain') != null || getValue(layout, 'exclude') != null) {
            result.items = []
        }
        return result
    })
    const itemLayouts = getLayout('item')
    const fieldLayouts = getLayout('field')
    const [widgets] = useState({ items: getItems(itemLayouts) })
    const [fields] = useState({ items: getItems(fieldLayouts) })
    return <div className="tabs">
        <ul className="tab-title">
            <li className={activeTab === 0 ? 'active' : ''} onClick={() => setActiveTab(0)}>Булан</li>
            <li className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>Талбар</li>
        </ul>
        <div className="tab-content">
            <div className={activeTab === 0 ? 'elements active' : 'elements'}>
                <Builder root={widgets}
                    renderNode={node => <div>{getValue(node, 'title')}</div >} />
            </div>
            <div className={activeTab === 1 ? 'elements active' : 'elements'}>
                <Builder root={fields}
                    renderNode={node => <div>{getValue(node, 'title')}</div >} />
            </div>
        </div>
    </div>
}
Dg41.After = After
export default Dg41