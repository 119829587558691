import * as ItemService from "../services/ItemService"

const itemsLoader = async (route: any) => {
    const url = new URL(window.location.href)
    let status = Number(url.searchParams.get('status'))
    let parentId = Number(url.searchParams.get('parent'))
    status = status > 0 ? status : 30
    const current = Number(url.searchParams.get('page')) > 1 ? Number(url.searchParams.get('page')) : 1

    let q: any = {
        status: status,
        category: Number(route.params.categoryId),
        $orderby: { created: 'desc' }
    }
    console.log('change to ', q.category, document.location.href)
    if (parentId > 0) {
        q.parent = parentId
    }
    let queryParam = { limit: 50, q: JSON.stringify(q), offset: 0 }
    queryParam.offset = (current - 1) * queryParam.limit
    console.log('query param', queryParam)
    return await ItemService.query(queryParam)
}

export default itemsLoader