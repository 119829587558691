import * as Service from './Service'
/**
 * Бүртгэлтэй хэрэглэгчийн мэдээллийг удирдах сервис
 */
const alias = 'user'
/**
 * Мэдээлэл удирдах сервис
 */
export const query = (params: any) => Service.query(alias, params)
/**
 * Өгөгдсөн дугаартай мэдээллийг авна
 * @param {number} id Авах мэдээллийн дугаар
 * @returns Мэдээлэл
 */
export const get = (id: number) => Service.get(alias, id)
/**
 * Мэдээлэл нэмнэ
 * @param {object} item Мэдээлэл
 * @returns 
 */
export const add = (item: any) => Service.add(alias, item)
/**
 * Мэдээлэл засна
 * @param {object} item Мэдээлэл
 * @returns 
 */
export const edit = (item: any) => Service.edit(alias, item.id, item)
/**
 * Мэдээлэл устгана
 * @param {number} id Устгах мэдээллийн дугаар
 * @returns 
 */
export const remove = (id: number) => Service.remove(alias, id)