import { useContext, useEffect, useState } from 'react'
import { Link, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as ItemService from '../services/ItemService'
import { getCategory, collectFields, getValue } from '../utils/valueUtils'
import Pagination from '../components/Pagination'
import { SiteContext } from '../contexts/SiteContext'
import PermissionModal from '../components/modal/PermissionModal'
import ExcelModal from '../components/modal/ExcelModal'
import { ItemProvider } from '../contexts/ItemContext'

function getUrl(i: any) {
    var url = new URL(window.location.toString())
    var search_params = url.searchParams
    // new value of "id" is set to "101"
    search_params.set('status', i)
    // change the search property of the main url
    url.search = search_params.toString()
    // the new url string
    return url.pathname + url.search
}
function Table() {
    const { categoryId } = useParams()
    const { site, getItemLayout } = useContext(SiteContext)
    const data = useLoaderData() as any
    const items = data.content
    const [fields, setFields] = useState([] as any[])
    const [visible, setVisible] = useState(false)
    const [excelVisible, setExcelVisible] = useState(false)
    const [permission, setPermission] = useState(null as any)
    const [title, setTitle] = useState('')
    const url = new URL(window.location.href)
    let status = Number(url.searchParams.get('status'))
    status = status > 0 ? status : 30
    const [category, setCategory] = useState<any>()
    const parent = Number(url.searchParams.get('parent'))
    const [pagination] = useState({ current: Number(url.searchParams.get('page')) > 1 ? Number(url.searchParams.get('page')) : 1, size: 0, total: 0 })
    const hasForm = () => {
        return category && getItemLayout(category, 'form').items?.length > 0
    }
    /*    const changePage = (current: number, status: number) => {
            q = {
                status: status,
                category: Number(categoryId),
                $orderby: { created: 'desc' }
            }
            console.log('change to ', q.category, document.location.href)
            if (Number(parentId) > 0) {
                q.parent = parentId
            }
            queryParam = { limit: 50, q: JSON.stringify(q), offset: 0 }
            queryParam.offset = (current - 1) * queryParam.limit
            ItemService.query(queryParam).then(result => result.json()).then(function (value) {
                //setItems(value.content)
                setPagination({ current: current, size: value.count, total: value.total })
            })
        }*/
    const remove = (item: any) => {
        if (window.confirm('Та "' + item.title + '" мэдээллийг устгаж байгаадаа итгэлтэй байна уу?')) {
            ItemService.remove(item).then(() => {
                //setItems(items.filter(itm => itm !== item))
            })
        }
    }
    const changeParam = (category: any) => {
        setVisible(true)
        setPermission({
            permission: category.param && category.param.itemPermission ?
                category.param.itemPermission : {},
            //TODO: defperm: site.defaults[0].param.itemPermission
            defperm: site.default.param
        })
        setTitle(getValue(category, 'title') + ' - ангилалд хандах эрх')
    }
    const onPermissionClose = (permission: any) => {
        if (!('param' in category)) {
            category.param = {}
        }
        category.param.itemPermission = permission
    }
    const excel = () => {
        setExcelVisible(true)
    }
    const onExcelClose = (result: any) => {
        //setItems(items.concat(result))
    }
    useEffect(() => {
        console.log('category changed ', categoryId)
        const cat = getCategory(site, Number(categoryId))
        if (cat) {
            var value = getItemLayout(cat, 'teaser')
            setFields(collectFields(value.items))
            //    changePage(pagination.current, status)
        }
        setCategory(cat)
    }, [site, categoryId])

    // Идэвхтэй хуудасны өөрчлөлтийг барьж, төлөв болон хуудсыг өөрчилнө.
    /*    useEffect(() => {
            console.log('history listen', location)
            const newUrl = new URL(location.pathname + location.search, window.location.toString())
            status = Number(newUrl.searchParams.get('status'))
            let page = Number(newUrl.searchParams.get('page'))
            changePage(page > 1 ? page : 1, status > 0 ? status : 30)
        }, [location])*/
    const renderHeader = () => <tr>
        <th className="action"></th>
        <th className="id">№</th>
        {fields.map((field, i) => <th key={i}>{getValue(field, 'title')}</th>)}
        <th className="action"></th>
    </tr>
    return <div className="box">
        <div className="action">
            <Link to={getUrl(30)} className={status === 30 ? 'btn btn-primary' : 'btn'} title="Нийтэлсэн">
                <i className="fa fa-eye"></i></Link>
            <Link to={getUrl(20)} className={status === 20 ? 'btn btn-primary' : 'btn'} title="Нийтлээгүй">
                <i className="fa fa-eye-slash"></i></Link>
            <Link to={getUrl(10)} className={status === 10 ? 'btn btn-primary' : 'btn'} title="Хаалттай">
                <i className="fa fa-ban"></i></Link>
            {hasForm() && <a href="" onClick={excel} className="btn btn-default">
                <i className="fa fa-file-excel-o"></i> Excel-ээс нэмэх</a>}
            {hasForm() && <Link to={parent ? "/items/" + parent +
                "/categories/" + category.id + "/items/new/form.html" :
                '/categories/' + category.id + '/items/new/form.html'} className="btn btn-success">
                <i className="fa fa-plus"></i> Шинийг нэмэх</Link>}
            <a href="" className="btn btn-default" onClick={changeParam} title="Тохируулах">
                <i className="fa fa-gear"></i></a>
        </div>
        <Pagination total={data.total} count={items.length} size={pagination.size} current={pagination.current} />
        <div className="table-wrapper">
            <table className="table table-striped table-bordered table-hover">
                <thead>
                    {renderHeader()}
                </thead>
                <tbody>
                    {items.map((item: any) => <tr key={item.id}>
                        <td className="edit">
                            <Link className="btn btn-primary btn-sm" to={'/items/' + item.id + '/form.html'}><i className="fa fa-pencil"></i></Link>
                        </td>
                        <td className="id">{item.id}</td>
                        {fields.map((field: any, i: number) => <td key={i} className={'f' + field.id}>
                            <ItemProvider item={item} layout={field} />
                        </td>)}
                        <td className="remove">
                            <button className="btn btn-danger btn-sm" onClick={() => { remove(item) }}><i className="fa fa-times"></i></button>
                        </td>
                    </tr>)}
                </tbody>
                <tfoot>
                    {renderHeader()}
                </tfoot>
            </table>
        </div>
        <Pagination total={data.total} count={items.length} size={pagination.size} current={pagination.current} />
        <PermissionModal title={title} visible={visible} onClose={onPermissionClose} item={permission} />
        {category && category.id ? <ExcelModal category={category} pntId={parent} visible={excelVisible} onClose={onExcelClose} item={undefined} title={''} /> : null}
    </div>
}
export default Table