import { FC } from 'react'
import { createPortal } from 'react-dom'
import { ItemProvider } from '../../contexts/ItemContext'

const Modal: FC<ModalProps> = ({ title, layout, item, visible, onClose }) => {
    return visible ? createPortal(<div className="modal-wrapper">
        <div className='modal'>
            <h3>{title}</h3>
            {layout && <ItemProvider item={item} layout={layout} />}
            <button className='close' onClick={() => onClose()}>
                <span className='fa fa-times'></span>
            </button>
        </div>
    </div>, document.body) : null
}
type ModalProps = {
    title: string
    visible: boolean
    onClose: any
    layout: any
    item: any
}
export default Modal